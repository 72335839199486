import _ from 'lodash';
import moment from 'moment';
import i18n from '../config/i18n';
import ModelLogic from "@/logics/ModelLogic";
import AuthLogic from "@/logics/AuthLogic";
import {EventBus} from "@/services/Request";

export default class Helpers {
    /**
     * @param currentPagination
     * @param newPagination
     */
    static syncPagination(currentPagination, newPagination) {
        currentPagination.page = newPagination.current_page;
        currentPagination.itemsPerPage = newPagination.per_page;
    }

    /**
     * @param pagination
     * @returns {{page, limit: *|number}}
     */
    static parsePagination(pagination) {

        let params = {
           /* page: false,
            limit: -1*/
        };

        if (pagination) {
            if (pagination.page && parseInt(pagination.page) > 0) {
                params.page = pagination.page;
            }

            if (pagination.itemsPerPage && parseInt(pagination.itemsPerPage) > 0) {
                params.limit = pagination.itemsPerPage;
            }

            _.merge(params, {
                //page: pagination.page && parseInt(pagination.page) > 0 ? parseInt(pagination.page) : params.page,
                //limit: pagination.itemsPerPage && parseInt(pagination.itemsPerPage) > 0,
                sortBy: pagination.sortBy && pagination.sortBy.length ? pagination.sortBy[0] : "created_at",
                descending: pagination.sortDesc && pagination.sortDesc.length ? +pagination.sortDesc[0] : "0"
            })

            return params;
        }

        return {
            sortBy: "created_at",
            descending: "0"
        };


        /*return {
            page: pagination.page,
            limit: pagination.itemsPerPage,
            sortBy: pagination.sortBy.length ? pagination.sortBy[0] : "created_at",
            descending: pagination.sortDesc.length ? +pagination.sortDesc[0] : "0"
        }*/
    }

    /**
     *
     * @param search
     * @returns {boolean}
     */
    static parseFilters(filters) {
        return _.pickBy(filters, (filter) => {
            return filter !== '';
        });
    }

    /**
     * @returns {{descending: boolean, page: number, rowsPerPage: number, sortBy: null, totalItems: number}}
     */
    static initPagination(rowsPerPage = 20, page = 1, sortBy = null, descending = null) {
        return {
            rowsPerPage,
            page,
            sortBy,
            descending,
        }

    }

    static ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static parseDate(date, format = 'DD/MM/YYYY', parseFormat = null, isIso8601String = false) {
        if(i18n.locale === 'en') {
            if(format === 'DD/MM/YYYY') {
                format = 'MM/DD/YYYY'
            }
            if(format === 'DD/MM/YYYY HH:mm:ss') {
                format = 'MM/DD/YYYY HH:mm:ss'
            }
        }

/*        if(isIso8601String) {
            return moment().parseZone(date).format(format)
        }*/

        return moment(date, parseFormat).format(format)
    }

    static parseActualDate(format = 'DD/MM/YYYY') {
        if(i18n.locale === 'en') {
            if(format === 'DD/MM/YYYY'){
                format = 'MM/DD/YYYY'
            }
            if(format === 'DD_MM_YYYY'){
                format = 'MM_DD_YYYY'
            }
        }
        return moment().format(format)
    }

    static isFormDirty(component) {
        const isFormDirty = Object.keys(component.fields).some(key => component.fields[key].dirty);

        component.$emit('update:isFormDirty', isFormDirty)

        return isFormDirty;
    }

    static getLocalizedDate(date) {
        return Helpers.ucFirst(date.format("dddd D MMMM YYYY, HH:mm"))
    }

    static truncate(string, length, dots = true){
        return string && (string.length > length) ? string.substr(0, length-1) + (dots ? '&hellip;' : '') : string;
    }

    static millisecondsToMinutesSeconds = (ms) => {
        if (!ms || ms === 0) return '00:00:00';

        let duration = moment.duration(ms, 'milliseconds');
        let fromMinutes = Math.floor(duration.asMinutes());
        let fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60);

        return Math.floor(duration.asSeconds()) >= 60 ? (fromMinutes <= 9 ? '0' + fromMinutes : fromMinutes) + ':' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds)
            : '00:' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds);
    }

    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static updateItemsPerPage (items_per_page) {
        const user = AuthLogic.user()
        if(user.items_per_page !== items_per_page){
            ModelLogic
                .updateOne('users', user.id + '/items_per_page', {'items_per_page': items_per_page})
                .then((result) => {
                    AuthLogic.removeUser()
                    AuthLogic.loadUser()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    /**
     * Returns translated field object for given lang from all translations object array
     * @param translationObject
     * @param lang
     * @param fallback
     * @returns {*|T}
     */
    static getTranslatedFieldObjectFromTranslationsObjectArray(translationObject, lang = null, fallback = false) {
        lang = lang ?? this.$i18n.locale;
        let translation = _.find(translationObject, function(o) { return o.locale === lang; });

        if(fallback && _.isEmpty(translation)) {
            return _.find(translationObject, function(o) { return o.locale === this.$i18n.fallbackLocale; });
        }

        return translation;
    }

    static getTranslatedFieldValueFromTranslationsObject(field, translationObject, lang = null, fallback = false) {
        let translation = this.getTranslatedFieldObjectFromTranslationsObjectArray(translationObject, lang, fallback);

        if(!_.isEmpty(translation) && translation.hasOwnProperty(field)) {
            return translation[field];
        }

        return '';
    }

    static isRouteWithGlobalFilters(routeName){
        return _.includes(['dashboards', 'ratings', 'dashboard-unit-group', 'dashboard'], routeName)
    }

    static convertMinutesInMilliseconds(time){
        return time*60000
    }

    static convertSecondsInMilliseconds(time){
        return time*1000
    }

    static convertRateToVRating(avgRate){
        if ((avgRate % 1) < 0.25) {
            avgRate = Math.round(avgRate);
        }
        return avgRate
    }

    static copyLink() {
        EventBus.$emit('show-snackbar', {text: 'Uuid copié', timeout: 2000})
    }

    static copyUuid() {
        EventBus.$emit('show-snackbar', {text: 'Uuid copié', timeout: 2000})
    }

    static copyId() {
        EventBus.$emit('show-snackbar', {text: 'Id copié', timeout: 2000})
    }

    static copyMessage(value, label) {
        EventBus.$emit('show-snackbar', {text: (label.charAt(0).toUpperCase() + label.slice(1)) + ' "' + value + '" copié', timeout: 2000})
    }

    static copy(txt, successMessage, errorMessage) {
        const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;

        clipboardData.writeText(txt).then((e) => {
            EventBus.$emit('show-snackbar', {
                text: successMessage ? successMessage : i18n.t('views.global.text_copy_success'),
                timeout: 2000,
                color: 'success'
            })
        }, (e) => {
            EventBus.$emit('show-snackbar', {
                text: errorMessage ? errorMessage : i18n.t('views.global.text_copy_fail'),
                timeout: 2000,
                color: 'red'
            })
            console.log(e)
        });
    }

    static searchFilters(item, queryText, itemText, attribute = 'name') {
        return item[attribute].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
    }
    static slugify(title) {
            let slug = "";
            // Change to lower case
            let titleLower = title.toLowerCase();

            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|û|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "'"
            slug = slug.replace(/'/gi, '');
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            slug = slug.replace('(', '-');
            slug = slug.replace(')', '-');
            slug = slug.replace(',', '');
            slug = slug.replace(';', '');
            slug = slug.replace("'", '');
            slug = slug.replace("?", '');
            slug = slug.replace("!", '');
            slug = slug.replace("/", '-');

            return slug;
    }
    static generateRandomToken(length) {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        const charactersLength = characters.length;
        let token = '';

        if (typeof window !== 'undefined' && window.crypto) {
            const randomValues = new Uint32Array(length);
            window.crypto.getRandomValues(randomValues);

            for (let i = 0; i < length; i++) {
                token += characters.charAt(randomValues[i] % charactersLength);
            }
        } else {
            // Fallback to Math.random() for environments without window.crypto support
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charactersLength);
                token += characters.charAt(randomIndex);
            }
        }

        return token;
    }

    static getDomainFromUrl(url, subdomain) {
        subdomain = subdomain || false;

        url = url.replace(/(https?:\/\/)?(www.)?/i, '');

        if (!subdomain) {
            url = url.split('.');

            url = url.slice(url.length - 2).join('.');
        }

        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }

        return url;
    }
}
