import Storage from '../services/Storage';
import UserRepo from "../repositories/UserRepository";
import UserStore from "../stores/UserStore";
import _ from 'lodash';
import moment from 'moment';
import i18n from '../config/i18n';
import RatingStore from "@/stores/RatingStore";
import GlobalFiltersStore from "@/stores/GlobalFiltersStore";
import Helpers from "../services/Helpers";

export default class AuthLogic {
    /**
     * @returns {Promise<any>}
     */
    static login({email, password}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .login({email, password})
                .then((result) => {

                    if(result.redirect) {
                        window.location.href = result.redirect;
                        return;
                    }

                    this.setTokens(result);
                    this.loadUser()
                        .then((result) => {
                            i18n.locale = result.data.locale;
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error)
                        });
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static loginFromOauthTokensFromSsoWebLogin({key}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .getOauthTokensFromSsoWebLogin({key})
                .then((result) => {
                    this.setTokens(result);
                    this.loadUser()
                        .then((result) => {
                            i18n.locale = result.data.locale;
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error)
                        });
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static resetPassword({email}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .resetPassword({email})
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static passwordFindToken(token) {
        return new Promise((resolve, reject) => {
            UserRepo
                .passwordFindToken(token)
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    /**
     * @returns {Promise<any>}
     */
    static passwordUpdate({email, password, token}) {
        return new Promise((resolve, reject) => {
            UserRepo
                .passwordUpdate({email, password, token})
                .then((result) => {
                    resolve(result)
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    // /**
    //  *
    //  * @param body
    //  * @returns {Promise<any>}
    //  */
    // static register(body) {
    //     return new Promise((resolve, reject) => {
    //         UserRepo
    //             .register(body)
    //             .then(() => resolve())
    //             .catch((error) => reject(error));
    //     });
    // }

    /**
     * @returns {Promise<any>}
     */
    static refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {
            this.deleteRefreshToken();

            UserRepo
                .refreshToken(refreshToken)
                .then((result) => {
                    this.setTokens(result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error)
                });
        });
    }

    static deleteRefreshToken() {
        let token = Storage.get('token');
        delete token.refresh_token;
        Storage.set('token', token);
    }

    /**
     *
     */
    static logout() {
        return new Promise((resolve, reject) => {
            if (this.getTokens()) {
                UserRepo.logout()
                    .then((result) => {
                        this.removeUser();
                        this.removeTokens();
                        UserStore.isLoggedIn = false;
                        RatingStore.filters = RatingStore.default_filters;
                        //RESET GLOBAL FILTERS
                        GlobalFiltersStore.selectedUnitGroups = [];
                        GlobalFiltersStore.selectedUnits = [];
                        GlobalFiltersStore.startDate = moment().subtract(12, 'months').format('YYYY-MM-DD');
                        GlobalFiltersStore.endDate = moment().format('YYYY-MM-DD');
                        GlobalFiltersStore.sources = [];
                        GlobalFiltersStore.uids = [];
                        resolve(result)
                    })
                    .catch((error) => {
                        reject(error)
                    });
            } else {
                resolve()
            }
        });
    }

    /**
     * @returns {boolean}
     */
    static hasAccessToken() {
        const hasAccessToken = this.getTokens();

        if (hasAccessToken) {
            UserStore.isLoggedIn = true;
        }

        return hasAccessToken
    }

    /**
     *
     */
    static loadUser() {
        return new Promise((resolve, reject) => {
            UserRepo
                .user()
                .then((result) => {
                    UserStore.user = result.data;
                    Storage.set('user', result.data);

                    if(result.data.metas.length) {
                        RatingStore.filters =  {
                            ...RatingStore.filters.default_filters,
                            ...JSON.parse(result.data.metas.find(x => x.key === 'default_filters').value)
                        }

                        if(RatingStore.filters.uuid === null) {
                            delete RatingStore.filters.uuid
                        }
                        if(RatingStore.filters.has_answers === null) {
                            RatingStore.filters.has_answers = ""
                        }
                        if(RatingStore.filters.has_comment === null) {
                            RatingStore.filters.has_comment = ""
                        }
                        if(RatingStore.filters.search === null) {
                            RatingStore.filters.search = ""
                        }
                        if(!RatingStore.filters.questions_answers) {
                            RatingStore.filters.questions_answers = []
                        }
                    }

                    resolve(result)
                })
                .catch((error) => reject(error))
        });
    }

    /**
     * @returns {string|undefined}
     */
    static user() {
        return Storage.get('user')
    }

    /**
     * @returns void
     */
    static removeUser() {
        Storage.remove('user')
    }

    /**
     * @param token
     */
    static setTokens(token) {
        token.espireDate = moment().add(token.expires_in, 'seconds');

        Storage.set('token', token);
        UserStore.isLoggedIn = true;
    }

    /**
     * @returns {string}
     */
    static getTokens() {
        return Storage.get('token')
    }

    /**
     * @returns {string}
     */
    static removeTokens() {
        Storage.remove('token')
    }

    static hasRole(role) {
        const user = this.user();

        return user && user.roles
            ? !!_.find(user.roles, {name: role})
            : false;
    }

    static userAlreadyLoggedIn(user) {
        if(user.metas && !_.isEmpty(user.metas)) {
            let meta = _.find(user.metas, (o) => {
                return o.key === 'last_connection';
            });
            meta = meta && !_.isEmpty(meta.value) ? meta.value : false;

            if(meta && meta.length) {
                return Helpers.parseDate(meta, 'DD/MM/YYYY HH[h]mm', 'YYYY-MM-DD HH:mm:ss')
            }

            return meta;
        }

        return false;
    }
}

