class RatingStore {
    constructor() {
        //WARNING : ADD VARIABLES TO DEFAULT_FILTERS BELOW (USEFULL FOR LOGOUT RESET FILTERS)
        this.filters = {
            search: '',
            in: '',
            sharedRatings: [],
            units: [],
            unit_groups: [],
            questions_answers: [],
            dates: [],
            rating_categories: {
                emotion: [],
                theme: [],
                context: []
            },
            has_answers: '',
            has_comment: '',
            standard_rating_ranges: [0, 100],
            consumer_influence: [0, 100],
            categorized_by: [],
            interesting_updated_by: [],
            interesting: 0,
            channels: [],
            sortBy: 'posted_at_updated',
            with: [
                'data',
                'answers',
                'contents.mentions.ratingCategories',
                'consumer',
                'questionAnswers.question.apps.units'
            ],
            with_fractal: [
                'data',
                'answers',
                'contents.mentions.ratingCategories.rating_mentions_rating_categories_pivot',
                'consumer',
                'place',
                'rating_channels',
                'questionAnswers.question.apps.units'
            ]
        };

        this.default_filters = {
            search: '',
            in: '',
            sharedRatings: [],
            units: [],
            unit_groups: [],
            questions_answers: [],
            dates: [],
            rating_categories: {
                emotion: [],
                theme: [],
                context: []
            },
            has_answers: '',
            has_comment: '',
            standard_rating_ranges: [0, 100],
            consumer_influence: [0, 100],
            categorized_by: [],
            interesting_updated_by: [],
            interesting: 0,
            channels: [],
            sortBy: 'posted_at',
            with: [
                'data',
                'answers',
                'contents.mentions.ratingCategories',
                'consumer',
                'questionAnswers.question',
                'channels'
            ],
            with_fractal: [
                'data',
                'answers',
                'contents.mentions.ratingCategories.rating_mentions_rating_categories_pivot',
                'consumer',
                'place',
                'questionAnswers.question',
                'channels'
            ]
        };

        this.inappropriateGroupsWithReasons = [];
        this.page = 1;
        this.ratingsToShare = [];
        this.questionsToSearch = [];
        this.ratingsToShareHasComment = false;
    }
}

export default new RatingStore();
